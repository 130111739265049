import styled from 'styled-components';

export const Wrapper = styled.div`
${({ theme }) =>
  `
    position: absolute;
    top: 52px;
    right: 0;
    height: 77%;
    background: #ddddddbb;
    overflow-y: scroll;
    transform: translate(100%, 0);
    transition: transform 600ms;
    &.visible {
      transform: translate(0, 0);
    }
    .parts-item {
      font-family: ${theme.fonts.newbalance};
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 7px 12px;
      border: 1px solid #fff;
      border-width: 0 0 1px 0;
}
    }
    .part-name {
      text-transform: uppercase;
      font-size: 13px;
    }
    .part-color-name {
      font-size: 11px;
      margin-top: 1px;
    }
    .part-color-circle {
      width: 20px;
      height: 20px;
      background: brown;
      border-radius: 50%;
      margin-left: 15px;
    }
  `}
`;
