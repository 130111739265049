import React, { useState, useRef } from 'react';
import Model from "../Model";
import {ActionBar, SceneWrapper} from "./Scene.style";
import SideParts from "../SideParts";
import SideColors from "../SideColors";
import {PresetPane} from "./PresetsPane";
import {VirtualModel, getColorHex, annotationLimits, getDefaultModel} from "../../consts/model-conf";
import {getCameraLimits} from "../../consts/camera-limits";
import {modelPresets} from "../../consts/model-presets";
import {SaveAndShare, toggleSaveAndShare} from "./SaveAndShare";
import {Hint} from "./components/Hint";
import Header from "../Header";
import {ThemeProvider} from "styled-components";
import {isMobileDevice} from "../../consts/model-conf";

const Scene = props => {
  const [sidePanelActive, setSidePanelVis] = useState(false);
  const [colorPanelActive, setColorPanelVis] = useState(false);
  const [materials, setMaterials] = useState(false);
  const [legsVisible, toggleLegs] = useState(false);
  const [annotationState, setAnnotation] = useState(false);
  const [annotationCurrent, setCurrentAnnotation] = useState(annotationLimits.default.min + 1);
  const [parts, setParts] = useState({...VirtualModel});
  const [currentPart, setCurrPart] = useState(parts.base_accent);
  const [saveAndShareState, setSaveAndShare] = useState(false);
  const [screenshot, setScreenshot] = useState(false);
  const [hintState, setHintState] = useState(true);
  // const defaultModel = JSON.parse(JSON.stringify(VirtualModel));

  const onActionShowParts = (sidePanelStatus) => {
    setSidePanelVis(sidePanelStatus);
    setColorPanelVis(false);
    window.resetHighlightedMat();
  };

  const setPropForModel = ({color}) => {
    const hexColor = getColorHex(color, '3d');
    currentPart.color = color;
    parts[currentPart.mat].color = color;
    setParts(parts);

    window.api.setColor({ material: currentPart.mat, hexColor: hexColor });
  };

  const changeCurrentMat = (el) => {
    const api = window.api;

    const annot = api._getAnnotationByName(el.mat);
    api.gotoAnnotation(annot.index);

    setCurrPart(el);
    setSidePanelVis(false);
    setColorPanelVis(true);
    window.resetHighlightedMat();
  };

  const setCameraLimit = type => {
    const limits = getCameraLimits(type);
    const api = window.api;

    api.setCameraConstraints(
      limits,
      function(err) {
        console.log(err);
      }
    );
  };

  const hideAndResetUi = () => {
    setSidePanelVis(false);
    setColorPanelVis(false);
    window.resetHighlightedMat();
  };

  const setLegsVis = (stat) => {
    const legs = typeof(stat) === "undefined" ? !legsVisible : stat;
    const api = window.api;
    console.warn('LEGS NOW', legs);
    toggleLegs(legs);
    const limits = legs ? annotationLimits.legs : annotationLimits.default;

    const nodeName = 'hide';

    if (legs) {
      setCameraLimit('legs');
      api.showNode(nodeName);
    }
    else {
      setCameraLimit('default');
      api.hideNode(nodeName);
    }

    // Switch annotation to current limit and set next one +1 (for use in useUpdateAnnotation on next trigger)
    api.gotoAnnotation(limits.min);
    setCurrentAnnotation(limits.min + 1);
    hideAndResetUi();
  };

  const switchSaveAndShare = () => {
    toggleSaveAndShare(saveAndShareState, setSaveAndShare, setScreenshot, setLegsVis, hideAndResetUi );
  };

  const onActionChangeView = () => {
    hideAndResetUi();

    setAnnotation(!annotationState);
  };

  const applyPreset = (n, reset = false) => {
    if (window.api) {
      setLegsVis(false);
      const preset = reset ? getDefaultModel() : modelPresets[n];
      setParts(preset);
      window.currentPreset = n;

      Object.keys(preset).forEach(el => {
        const mat = preset[el];
        const hexColor = getColorHex(mat.color, '3d');
        window.api.setColor({material: el, hexColor: hexColor});
      });
    }
  };

  // window.defaultModel = getDefaultModel();
  window.applyPreset = applyPreset;

  const onActionFullscreen = () => {
    window.sf_no_hover = true;
    document.querySelector("#sweater").requestFullscreen();
  };

  return (
    <SceneWrapper id="scene">
      <div className="wrapper-rel">
        <Header />
        <div className="scroller"/>

        <SaveAndShare visible={saveAndShareState} screenshot={screenshot} switch={switchSaveAndShare} />

        <Model
          setMaterials={setMaterials}
          legsState={legsVisible}
          toggleLegs={toggleLegs}
          annotation={annotationState}
          setAnnotation={setAnnotation}
          annotationCurrent={annotationCurrent}
          setCurrentAnnotation={setCurrentAnnotation}
          changeCurrentMat={changeCurrentMat}
          parts={parts}
          setColorPanelVis={setColorPanelVis}
          setCameraLimit={setCameraLimit}
          setHintState={setHintState}
        />

        <SideParts visible={sidePanelActive} parts={parts} changeCurrentMat={changeCurrentMat} />

        <SideColors visible={colorPanelActive} toggleVisible={setColorPanelVis} currentPart={currentPart} setModelProp={setPropForModel} legsState={legsVisible} hideUi={hideAndResetUi} />

        <ActionBar id="action-bar">
          <div className="action action-parts" onClick={e => onActionShowParts(!sidePanelActive)}>Part list</div>
          <div className="action action-view" onClick={e => onActionChangeView()}>View</div>
          <div className="action action-try" onClick={e => setLegsVis()}>Fit</div>
          <div className="action action-fullscreen" onClick={e => onActionFullscreen()}>Fullscreen</div>
          <div className="action action-reset" onClick={e => applyPreset(0, true)}>Reset</div>
          <div className="action action-save" onClick={e => switchSaveAndShare()}>Share</div>
        </ActionBar>

        <Hint visible={hintState}/>
      </div>

    </SceneWrapper>
  );
};

export default Scene;
