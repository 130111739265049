import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import theme from 'consts/theme';
import store from 'state/store';
import Scene from "./components/Scene";
import {PresetPane} from "./components/Scene/PresetsPane";

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Scene/>
        <PresetPane/>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
