import styled from 'styled-components';

export const SceneWrapper = styled.div`
  .wrapper-rel {
    position: relative;
  }
  .scroller {
    position: absolute;
    right: 0;
    height: 100%;
    width: 8%;
  }
`;

export const ActionBar = styled.div`
${({ theme }) =>
  `
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 0;
  background-color: #ddddddbb;
  z-index: 5;
  .action {
    font-family: ${theme.fonts.newbalance};
    font-size: 8px;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    width: 62px;
    height: 58px;
    padding-top: 44px;
    padding-bottom: 5px;
    background: transparent url(/assets/icons/parts_list.svg?x2) no-repeat center 2px;
    background-size: auto 46px;
    transition: background-color 500ms;
    cursor: pointer;
    &:active {
      background-color: #bbb;
    }
    &-view {
      background-image: url(/assets/icons/view.svg?x2);
    }
    &-try {
      background-image: url(/assets/icons/fit.svg?x3);
    }
    &-fullscreen {
      background-image: url(/assets/icons/fullscreen.svg?x3);
    }
    &-reset {
      background-image: url(/assets/icons/reset.svg?x3);
      width: 54px;
    }
    &-save {
      background-image: url(/assets/icons/share.svg?x3);
      width: 50px;
    }
  }
  @media (max-width: 640px) {
    .action-fullscreen {
      display:none;
    }
  }
  @media (min-width: 640px) {
    .action {
      font-size: 9px;
      width: 66px;
      height: 62px;
      padding-top: 48px;
      padding-bottom: 5px;
      background-size: auto 48px;
    }
    .action:hover {
      background-color: #bbb;
    }
  }
  `}
`;
