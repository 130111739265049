/**
 * Created by Chupzzz on 21.02.2020.
 *
 */
export const Parts = {
  toe: {
    name:   'Toe',
    mat:    'toe',
    color:  'nimbus cloud',
    colors: []
  },
  heel: {
    name:   'Heel',
    mat:    'heel',
    color:  'nimbus cloud',
    colors: []
  },
  heel_tab: {
    name:   'Heel Tab',
    mat:    'heel_tab',
    color:  'nimbus cloud',
    colors: []
  },
  base_underlay: {
    name:   'Base Underlay',
    mat:    'base_underlay',
    color:  'nimbus cloud',
    colors: []
  },
  base_accent: {
    name:   'Base Accent',
    mat:    'base_accent',
    color:  'nimbus cloud',
    colors: []
  },
  saddle: {
    name:   'Saddle',
    mat:    'saddle',
    color:  'nimbus cloud',
    colors: []
  },
  collar: {
    name:   'Collar',
    mat:    'collar',
    color:  'nimbus cloud',
    colors: []
  },
  base_n_logo: {
    name:   'Base N logo',
    mat:    'base_n_logo',
    color:  'nimbus cloud',
    colors: []
  },
  top_n_logo: {
    name:   'Top N logo',
    mat:    'top_n_logo',
    color:  'nimbus cloud',
    colors: []
  },
  lining: {
    name:   'Lining',
    mat:    'lining',
    color:  'nimbus cloud',
    colors: ["kool grey", "black", "new navy", "nimbus cloud"]
  },
  emb_997: {
    name:   'Emb 997',
    mat:    'emb_997',
    color:  'kool grey',
    colors: []
  },
  emb_s: {
    name:   'Emb S',
    mat:    'emb_s',
    color:  'nimbus cloud',
    colors: []
  },
  laces: {
    name:   'Laces',
    mat:    'laces',
    color:  'nimbus cloud',
    colors: []
  },
  laces_holder: {
    name:   'Laces Holder',
    mat:    'laces_holder',
    color:  'nimbus cloud',
    colors: ["kool grey", "black", "new navy", "nimbus cloud"]
  },
  plastic_details: {
    name:   'Plastic Details',
    mat:    'plastic_details',
    color:  'nimbus cloud',
    colors: []
  },
  sole: {
    name:   'Sole',
    mat:    'sole',
    color:  'nimbus cloud',
    colors: []
  },
  tongue: {
    name:   'Tongue',
    mat:    'tongue',
    color:  'nimbus cloud',
    colors: []
  },
  tongue_line: {
    name:   'Tongue Line',
    mat:    'tongue_line',
    color:  'nimbus cloud',
    colors: []
  },
};

export const Colors = {
  // 1 - UI, 2 - 3D
  "kool grey":      ["#a1a5a9", "#a1a5a9"],
  "castlerock":     ["#5e5e61", "#5e5e61"],
  "black":          ["#221f20", "#383838"],
  "new navy":       ["#353e4b", "#383F48"],
  "team royal":     ["#1c3a7e", "#314268"],
  "angora cream":   ["#f0e8d4", "#D1C6AD"],
  "team red":       ["#a8152b", "#93303B"],
  "NB burgundy":    ["#522c35", "#3F2B30"],
  "sunlight":       ["#ffd831", "#E7C531"],
  "light reef":     ["#99dbd3", "#85C0B9"],
  "madder rose":    ["#a96c7d", "#8A5967"],
  "prism purple":   ["#523c7b", "#4D455E"],
  "varsity green":  ["#267951", "#216042"],
  "varsity orange": ["#de6624", "#A94F1E"],
  "wax blue":       ["#639bae", "#4C7583"],
  "work wear":      ["#9b7143", "#674B2C"],
  "nimbus cloud":   ["#eaeae8", "#F8F8F8"]
};

export const annotationLimits = {
  default: {
    min: 0,
    max: 7
  },
  legs: {
    min: 8,
    max: 15
  },
};

/**
 * Get HEX for color name.
 *
 * @param name
 * @param type
 * Web - for UI, 3D - for use in Model
 * @returns string
 * HEX value
 */
export const getColorHex = function(name, type = 'web') {
  return type === 'web' ? Colors[name][0] : Colors[name][1];
};

const prepareModel = function () {
  console.warn('PREPARING MODEL');
  let virtual = JSON.parse(JSON.stringify(Parts));

  Object.keys(virtual).map(n => {
    const {color, mat, colors} = virtual[n];

    if (!colors.length) {
      virtual[n].colors = Object.keys(Colors);
    }
  });

  return virtual;
};

export const VirtualModel = prepareModel();
export const getDefaultModel = prepareModel;

export const isMobileDevice = function () {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
};
