/**
 * Created by Chupzzz on 23.02.2020.
 */

function highlightMat(mat, state) {
  if (typeof mat === "object" && !mat.name.includes('_noconfig') && !mat.name.includes('_nocongig')) {
    const hilightColor = window.api.hexToLiner('#e21836');
    const factor = state ? 1 : 0;
    const color = state ? hilightColor : [0, 0, 0];

    const _mat = {...mat};
    _mat.channels.EmitColor.enable = true;
    _mat.channels.EmitColor.factor = factor;
    _mat.channels.EmitColor.color = color;
    window.api.setMaterial(_mat);
  }
}

window.highlightMat = highlightMat;

window.setHilighted = function (matName) {
  const mat = window.api._getMaterialByName(matName);
  highlightMat(mat, true);
  window.sf_no_hover = mat;
};

window.resetHighlightedMat = function (){
  if (window.sf_no_hover) {
    window.highlightMat(window.sf_no_hover, false);
    window.sf_no_hover = false;
  }
};

document.addEventListener("fullscreenchange", function (event) {
  if (document.fullscreenElement) {
    window.sf_no_hover = true;
  } else {
    window.sf_no_hover = false;
  }
});

export const ApiEventsSet = (api, model, callbacks) => {
  // CLICK
  api.addEventListener(
    'click',
    function (event) {
      // eslint-disable-next-line no-console
      // console.log(event.material);
      const mat = event.material ? model[event.material.name] : false;
      const el = mat || false;

      // window.parent.sf_stop_spinner();

      if (el) {
        callbacks.changeMat(el);
        // Save highlighted material while choosing color
        window.setHilighted(el.mat);
      }
      else {
        callbacks.setColorPanelVis(false);
      }
      callbacks.setHintState(false);
    },
    {pick: 'slow'}
  );

  api.addEventListener(
    'nodeMouseEnter',
    function(node) {
      if (!window.sf_no_hover) {
        highlightMat(node.material, true);
      }
    },
    { pick: 'fast' }
  );

  api.addEventListener(
    'nodeMouseLeave',
    function(node) {
      if (!window.sf_no_hover) {
        highlightMat(node.material, false);
      }
    },
    { pick: 'fast' }
  );
};
