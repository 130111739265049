/**
 * Created by Chupzzz on 23.02.2020.
 */

import React, {useState} from 'react';
import styled from 'styled-components';
import {modelPresetsTmb} from "../../consts/model-presets";

export const PresetPane = props => {
  console.warn('PRESETS REDRAW');
  // const [preset, setPreset] = useState(100);

  function applyPreset(n) {
    // setPreset(n);
    window.applyPreset(n);
  }

  const Wrap = styled.div`
    //position: absolute;
    //bottom: -125px;
    width: 100%;
    h2 {
     font-family: ${({ theme }) => theme.fonts.newbalance};
     font-size: 12px;
     text-transform: uppercase; 
     text-align: center;
     margin: 20px 0 0 0;
    }
    .wrapper {
      display: flex;
      justify-content: center;
      margin: 10px 0;
      .item {
        margin: 0 2vw;
        border: 1px solid #fff;
        transition: opacity 500ms;
        &:active {
          opacity: 0.5;
        }
      }
    }
    @media (min-width: 640px) {
      .wrapper .item {
        transition: transform 300ms;
        cursor: pointer;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
`;

  return (
    <Wrap id="preset-pane">
      <h2>Need inspiration? Start here</h2>
      <div className="wrapper">
        {modelPresetsTmb.map((el, n) => (
          <div onClick={e => applyPreset(n)} className="item">
            <img src={'/assets/presets_tmb/' + el} alt={n} />
          </div>
        ))}
      </div>
    </Wrap>
  )
};
