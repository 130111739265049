/**
 * Created by Chupzzz on 25.02.2020.
 */
import React, { useState } from 'react';
import styled from "styled-components";
import {ReactComponent as Pointer } from "./pointer.svg";
import {useSelector} from "react-redux";
import { selectIsLoading } from 'state/selectors';

const is_touch_device = function() {
  var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
  var mq = function(query) {
    return window.matchMedia(query).matches
  }

  if ('ontouchstart' in window || navigator.MaxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
    return true
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('')
  return mq(query)
};

export const Hint = function (props) {
  const Wrap = styled.div`
    font-family: ${({ theme }) => theme.fonts.newbalance};
    position: absolute;
    top: 62%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, 0%);
    text-align: center;
    pointer-events: none;
    z-index: 5;
    .text {
      line-height: 1.4rem;
    }
    .svg:before {
      content: '';
      height: 12px;
      width: 12px;
      background: transparent;
      position: absolute;
      top: 65%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 100%;
      animation: pulse-shadow 2s infinite;
      margin: -46px 0px 0 -14px;
    }
  `;
  const isLoading = useSelector(selectIsLoading);

  const text = is_touch_device()
    ? "Touch an area to design<br>Rotate by touch and drag"
    : "Click on an area to design<br>Rotate by click and drag";

  const className = !isLoading && props.visible ? 'visible' : 'hide';

  return (
    <Wrap id="hint" className={className}>
      <div className="svg"><Pointer /></div>
      <div className="text" dangerouslySetInnerHTML={{__html: text}} />
    </Wrap>
  );
};
