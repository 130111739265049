import React, { useState } from 'react';
import styled from "styled-components";
import {annotationLimits} from "../../consts/model-conf";

/**
 * Created by Chupzzz on 24.02.2020.
 */

export const SaveAndShare = function (props) {
  const Wrap = styled.div`
    position: absolute;
    top: 60px;
    left: 50%;
    width: 96%;
    height: 80%;
    overflow: hidden;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 15000;
    transform: translateY(-900px) translateX(-50%);
    transition: all 600ms;
    
    @media (min-width: 640px) {
      width: 60%;
      height: 100%;
      top: 0;
    }
    &.vis {
      transform: translateY(0) translateX(-50%);
    }
    .shareWrap {
      margin-top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (min-width: 640px) {
        margin-top: -30px;
      }
      .icon {
        width: 40px;
        height: 40px;
        margin: 0 4px;
        background: transparent url('/assets/icons/twitter.svg') center center no-repeat;
        background-size: contain;
        &.icon-fb {
          background-image: url('/assets/icons/facebook.svg');
        }
        &.icon-pi {
          background-image: url('/assets/icons/pinterest.svg');
        }
        &.icon-ig {
          background-image: url('/assets/icons/instagram.svg');
        }
      }
    }
    .screenshot {
      @media (max-width: 640px) {
        object-fit: cover;
        height: 36vh;
      }
      @media (min-width: 640px) {
        //width: 92%;
        //max-width: 100%;
        height: 56vh;
        object-fit: cover;
      }
    }
    .ld img {
      width: 52px;
    }
    
    .close {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      img {
        width: 30px;
        height: 30px;
      }
    }
`;

  const classVis = props.visible ? 'vis' : 'hid';

  return (
    <Wrap id='save-n-share' className={classVis}>
      <div className="close" onClick={props.switch}><img src="/assets/icons/close.svg" alt="" /></div>
      {!props.screenshot &&
        <div className='ld'><img src="/assets/loading.gif?x2" alt="" /></div>
      }
      {props.screenshot &&
        <div>
          <img src={props.screenshot} alt="" className="screenshot" />
          <div className="shareWrap">
            <div className="icon icon-tw" />
            <div className="icon icon-pi" />
            <div className="icon icon-fb" />
            <div className="icon icon-ig" />
          </div>
        </div>
      }
    </Wrap>
  )
};

export const toggleSaveAndShare = function (state, setState, setScreenshot, setLegs, hideUi) {
  document.querySelector('#share-bg').classList.toggle('vis');
  if (!state) {
    hideUi();

    window.api.showNode('hide');
    window.api.gotoAnnotation(annotationLimits.legs.min, {preventCameraAnimation: true, preventCameraMove: false}, function (err, index) {
      if (!err) {
        setTimeout(() => {
          window.api.getScreenShot(720, 480, function (err, result) {
            setScreenshot(result);
            setLegs(false);
          });
        }, 900);
      }
    });

    setState(true);
  }

  if (state) {
    // setLegs(false);

    setScreenshot(false);
    setState(false);
  }
};
