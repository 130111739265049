import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import SketchfabWrapper from 'sketchfab-api-wrapper';
import {annotationLimits} from "../../consts/model-conf";

import { setStartLoading, setStopLoading } from 'state/reducer';

export const useSketchfab = ({ uid, iframeId, options, onModelReady }) => {
  const [materials, setMaterials] = useState(null);

  const sketchFabApiRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setStartLoading());
    const init = async () => {
      const loadWrapperPromise = new SketchfabWrapper({
        iframeQuery:  `#${iframeId}`,
        uid:          uid,
        options:      options,
        version:      '1.7.1',
        useSingleton: false
      });

      const wrapper = await loadWrapperPromise.init();

      const api = { ...wrapper, ...wrapper.api };

      sketchFabApiRef.current = api;
      window.api = api;

      onModelReady(api);
      setMaterials(api.materials);

      setTimeout(() => dispatch(setStopLoading()), 700 );
    };
    init();
  }, [dispatch, iframeId, options, uid]);

  return [materials, sketchFabApiRef.current];
};

export const useUpdateAnnotation = (api, annotationState, current, setCurrent, legsState) => {
  useEffect(() => {
    if (api) {
      const limits = legsState ? annotationLimits.legs : annotationLimits.default;
      let _current = current < limits.min ? limits.min : current;
      _current = _current > limits.max ? limits.min : _current;

      api.gotoAnnotation(_current);

      let next = _current + 1;
      setCurrent(next === limits.max ? limits.min : next);

      console.warn('ANNOTATION', current, legsState, limits);
    }
  }, [annotationState]);
};
