import styled from 'styled-components';

export const Wrapper = styled.div`
    font-family: ${({ theme }) => theme.fonts.newbalance};
    position: absolute;
    top: 52px;
    right: 0;
    max-height: 77%;
    transform: translate(100%, 0);
    transition: transform 600ms;
    &.visible {
      transform: translate(0, 0);
    }
    .wrapper {
      overflow-y: scroll;
      background: #ddddddbb;
      height: 500px;
    }
    h2 {
      text-align: center;
      font-size: 13px;
      margin: 5px 0;
    }
    .color-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 7px 12px;
      border: 1px solid #fff;
      border-width: 1px 0 0 0;
    }
    .color-name {
      text-transform: lowercase;
      font-size: 11px;
    }
    .color-circle {
      width: 20px;
      height: 20px;
      background: brown;
      border-radius: 50%;
      margin-left: 15px;
    }
`;
