import styled from 'styled-components';
import { color } from 'styled-system'

export const HeadingWrapper = styled.div`
  ${({ theme }) =>
  `
    position: absolute;
    width: 100%;
    background-color: #ddddddbb;
    opacity: 0.8;
    padding-top: ${theme.space.medium}px;
    padding-bottom: ${theme.space.medium}px;
    z-index: 20;
  `}
`;

export const Heading = styled.div`
  ${({ theme }) =>
    `
    display: flex;
    justify-content: space-between;
    
    font-size: ${theme.fontSizes.xlarge};
    line-height: ${theme.fontSizes.xxlarge};
    color: ${theme.colors.primary};

    .title, .price {
      line-height: 1;
    }
    .wrapper {
      color: #000;
      padding: 0.2rem 0.5rem;
    }
    .title {
      font-size: 1.2rem;
      font-weight: 600;
    }
    .price {
      font-size: 0.9rem;
      margin-top: 3px;
    }
    .add-to-cart {
      height: 44px;
      .icon-cart {
        margin: 0;
      }
    }
  `}
`;

// ${theme.mediaQueries.large} {
//   color: ${theme.colors.white};
// }

export const CartButton = styled.button`
  //background: green;
  //background-image: url('/icons/play.png');
  //background-position: center;
  //background-repeat: no-repeat;
  //background-size: 30px 30px;
  //background-position: center;
`;
