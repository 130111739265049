import styled from 'styled-components';

export const Wrapper = styled.div`
  height: ${({ theme }) => theme.modelHeight};
  width: 100%;
  background: #eee;
  z-index: 10;
  //@media (min-width: 640px) {
  //  height: 55vh;
  //  min-height: 550px;
  //}
  iframe {
    border: none;
    height: 100%;
    width: 100%;
    opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};
  }
  .materialsWrapper {
    padding: ${({ theme }) => theme.space.medium};
    border: 1px solid ${({ theme }) => theme.colors.white};
    width: max-content;

    .materialsList {
      padding-left: ${({ theme }) => theme.space.medium};
    }
  }
`;

export const Loader = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  line-height: ${({ theme }) => theme.modelHeight};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-family: ${({ theme }) => theme.fonts.newbalance};
  text-align: center;
  transition: opacity 800ms;
  opacity: 1.0;
  background: #fff url('/assets/loading_blurred.jpg?x1') center center no-repeat;
  &.hid {
    opacity: 0.0;
    pointer-events: none;
  }
  .wrapper {
    background: #e2183640;
    color: #fff;
    display: inline;
    padding: 10px 30px 10px 20px;
    border-radius: 6px;
    text-transform: uppercase;
  }
  img {
    width: 52px;
  }
  
  span {
    &:nth-of-type(1) {
      animation: dots 1s 0s steps(5, end) infinite;
    }
    &:nth-of-type(2) {
      animation: dots 1s 0.2s steps(5, end) infinite;
    }
    &:nth-of-type(3) {
      animation: dots 1s 0.4s steps(5, end) infinite;
    }
  }
`;
