import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducer from './reducer';

let middleware = undefined;

if (process.env.NODE_ENV === 'development') {
  middleware = composeWithDevTools();
}

export default createStore(reducer, middleware);
