/**
 * Created by Chupzzz on 23.02.2020.
 */

/**
 * Up / Down / Left / Right - in RADIANS, not in degrees. Use converter.
 */
const cameraLimits = {
  // Apply for each presets
  base: {
    useCameraConstraints: true,
    usePanConstraints: true,
    usePitchConstraints: true,
    useZoomConstraints: true
  },

  // General view: without legs
  default: {
    down: -1.4,
    up: 1.4,
    zoomIn: 1800,
    zoomOut: 3900,
  },

  // Legs are visible
  legs: {
    down: -0.28,
    up: 0.37,
    zoomIn: 1800,
    zoomOut: 3900,
  }
};

export const getCameraLimits = type => {
  return {...cameraLimits.base, ...cameraLimits[type]}
};
