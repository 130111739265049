import React, {useState, useRef, useEffect} from 'react';
import {Wrapper} from "./SideColors.style";
import {getColorHex} from "../../consts/model-conf";
import {annotationLimits} from "../../consts/model-conf";

function SideColorItem(props) {
  const color = props.color;
  const legsState = props.legsState;
  // const part = props.part;
  // const [color, setColor] = useState(props.element.color);
  const hex = getColorHex(color);

  const setColor = () => {
    const limits = legsState ? annotationLimits.legs : annotationLimits.default;
    console.warn('COLOR LIMITS', legsState, limits);
    props.setModelProp({color: color});
    // props.togglePanel(false);
    props.hideUi();
    window.api.gotoAnnotation(limits.min);
    window.sf_no_hover = false;
  };

  return (
    <div className="color-item" onClick={e => setColor()}>
      <div>
        <div className="color-name">{color}</div>
      </div>
      <div>
        <div className="color-circle" style={{backgroundColor: hex}} />
      </div>
    </div>
  )
}

const SideColors = props => {
  const stageCanvasRef = useRef(null);

  useEffect( function() {
    if (stageCanvasRef.current) {
      const dom_el = stageCanvasRef.current;
      const scene_h = document.querySelector('#scene').offsetHeight;
      const action_h = document.querySelector('#action-bar').offsetHeight;
      dom_el.style.height = (scene_h - action_h - 30) + 'px';

      dom_el.querySelector('.wrapper').style.height = (dom_el.offsetHeight - 25) + 'px';
    }
  });

  const part = props.currentPart;
  const classVis = props.visible ? 'visible' : '';

  return (
    <Wrapper id="side-colors" className={classVis} ref={stageCanvasRef}>
      <h2>{part.name}</h2>
      <div className="wrapper">
        {Object.keys(part.colors).map(el => (
          <SideColorItem color={part.colors[el]} key={el} part={part} setModelProp={props.setModelProp} togglePanel={props.toggleVisible} legsState={props.legsState} hideUi={props.hideUi}  />
        ))}
      </div>
    </Wrapper>
  );
};

export default SideColors;
