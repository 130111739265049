const colors = {
  primary: '#333',
  white: '#eee'
};

const space = ['0px', '5px', '15px', '30px'];
space.small = space[1];
space.medium = space[2];
space.large = space[3];

const breakpoints = ['320px', '480px', '768px', '992px', '1200px'];

const mediaQueries = {
  xsmall: `@media screen and (min-width: ${breakpoints[0]})`,
  small: `@media screen and (min-width: ${breakpoints[1]})`,
  medium: `@media screen and (min-width: ${breakpoints[2]})`,
  large: `@media screen and (min-width: ${breakpoints[3]})`,
  xlarge: `@media screen and (min-width: ${breakpoints[4]})`
};

const fonts = {
  newbalance: "proxima nova w01"
}

const fontSizes = ['12px', '14px', '16px', '20px', '24px', '32px'];
fontSizes.xsmall = fontSizes[0];
fontSizes.small = fontSizes[1];
fontSizes.medium = fontSizes[2];
fontSizes.large = fontSizes[3];
fontSizes.xlarge = fontSizes[4];
fontSizes.xxlarge = fontSizes[5];

const modelHeight = '66vh';

export default {
  colors,
  fonts,
  fontSizes,
  space,
  breakpoints,
  mediaQueries,
  modelHeight
};
