import React, {useState} from 'react';
import {Wrapper} from "./SideParts.style";
import {getColorHex} from "../../consts/model-conf";

function SidePartsItem(props) {
  const el = props.element;
  const hex = getColorHex(el.color);

  return (
    <div className="parts-item" onClick={e => props.changeCurrentMat(el)}>
      <div>
        <div className="part-name">{el.name}</div>
        <div className="part-color-name">{el.color}</div>
      </div>
      <div>
        <div className="part-color-circle" style={{backgroundColor: hex}} />
      </div>
    </div>
  )
}

const SideParts = props => {
  // const materials = props.materials;
  const classVis = props.visible ? 'visible' : '';
  const parts = props.parts;

  return (
      <Wrapper id="side-parts" className={classVis}>
        <div className="wrapper">
          {Object.keys(parts).map(el => (
            // {parts.map(({name, mat, color}) => (
              <SidePartsItem element={parts[el]} changeCurrentMat={props.changeCurrentMat}/>
          ))}
        </div>
    </Wrapper>
  );
};

export default SideParts;
