/**
 * Created by Chupzzz on 23.02.2020.
 */

export const modelPresets = [
  {
    "toe": {
      "name": "Toe",
      "mat": "toe",
      "color": "black",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "heel": {
      "name": "Heel",
      "mat": "heel",
      "color": "light reef",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "heel_tab": {
      "name": "Heel Tab",
      "mat": "heel_tab",
      "color": "black",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "base_underlay": {
      "name": "Base Underlay",
      "mat": "base_underlay",
      "color": "castlerock",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "base_accent": {
      "name": "Base Accent",
      "mat": "base_accent",
      "color": "wax blue",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "saddle": {
      "name": "Saddle",
      "mat": "saddle",
      "color": "wax blue",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "collar": {
      "name": "Collar",
      "mat": "collar",
      "color": "black",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "base_n_logo": {
      "name": "Base N logo",
      "mat": "base_n_logo",
      "color": "nimbus cloud",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "top_n_logo": {
      "name": "Top N logo",
      "mat": "top_n_logo",
      "color": "castlerock",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "lining": {
      "name": "Lining",
      "mat": "lining",
      "color": "kool grey",
      "colors": [
        "kool grey",
        "black",
        "new navy",
        "nimbus cloud"
      ]
    },
    "emb_997": {
      "name": "Emb 997",
      "mat": "emb_997",
      "color": "varsity orange",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "emb_s": {
      "name": "Emb S",
      "mat": "emb_s",
      "color": "black",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "laces": {
      "name": "Laces",
      "mat": "laces",
      "color": "nimbus cloud",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "laces_holder": {
      "name": "Laces Holder",
      "mat": "laces_holder",
      "color": "kool grey",
      "colors": [
        "kool grey",
        "black",
        "new navy",
        "nimbus cloud"
      ]
    },
    "plastic_details": {
      "name": "Plastic Details",
      "mat": "plastic_details",
      "color": "varsity orange",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "sole": {
      "name": "Sole",
      "mat": "sole",
      "color": "kool grey",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "tongue": {
      "name": "Tongue",
      "mat": "tongue",
      "color": "wax blue",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "tongue_line": {
      "name": "Tongue Line",
      "mat": "tongue_line",
      "color": "light reef",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    }
  },
  {
    "toe": {
      "name": "Toe",
      "mat": "toe",
      "color": "angora cream",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "heel": {
      "name": "Heel",
      "mat": "heel",
      "color": "angora cream",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "heel_tab": {
      "name": "Heel Tab",
      "mat": "heel_tab",
      "color": "varsity orange",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "base_underlay": {
      "name": "Base Underlay",
      "mat": "base_underlay",
      "color": "angora cream",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "base_accent": {
      "name": "Base Accent",
      "mat": "base_accent",
      "color": "angora cream",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "saddle": {
      "name": "Saddle",
      "mat": "saddle",
      "color": "angora cream",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "collar": {
      "name": "Collar",
      "mat": "collar",
      "color": "angora cream",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "base_n_logo": {
      "name": "Base N logo",
      "mat": "base_n_logo",
      "color": "angora cream",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "top_n_logo": {
      "name": "Top N logo",
      "mat": "top_n_logo",
      "color": "varsity orange",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "lining": {
      "name": "Lining",
      "mat": "lining",
      "color": "nimbus cloud",
      "colors": [
        "kool grey",
        "black",
        "new navy",
        "nimbus cloud"
      ]
    },
    "emb_997": {
      "name": "Emb 997",
      "mat": "emb_997",
      "color": "wax blue",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "emb_s": {
      "name": "Emb S",
      "mat": "emb_s",
      "color": "varsity orange",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "laces": {
      "name": "Laces",
      "mat": "laces",
      "color": "nimbus cloud",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "laces_holder": {
      "name": "Laces Holder",
      "mat": "laces_holder",
      "color": "nimbus cloud",
      "colors": [
        "kool grey",
        "black",
        "new navy",
        "nimbus cloud"
      ]
    },
    "plastic_details": {
      "name": "Plastic Details",
      "mat": "plastic_details",
      "color": "wax blue",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "sole": {
      "name": "Sole",
      "mat": "sole",
      "color": "wax blue",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "tongue": {
      "name": "Tongue",
      "mat": "tongue",
      "color": "angora cream",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "tongue_line": {
      "name": "Tongue Line",
      "mat": "tongue_line",
      "color": "nimbus cloud",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    }
  },
  {
    "toe": {
      "name": "Toe",
      "mat": "toe",
      "color": "black",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "heel": {
      "name": "Heel",
      "mat": "heel",
      "color": "black",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "heel_tab": {
      "name": "Heel Tab",
      "mat": "heel_tab",
      "color": "black",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "base_underlay": {
      "name": "Base Underlay",
      "mat": "base_underlay",
      "color": "black",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "base_accent": {
      "name": "Base Accent",
      "mat": "base_accent",
      "color": "black",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "saddle": {
      "name": "Saddle",
      "mat": "saddle",
      "color": "black",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "collar": {
      "name": "Collar",
      "mat": "collar",
      "color": "black",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "base_n_logo": {
      "name": "Base N logo",
      "mat": "base_n_logo",
      "color": "nimbus cloud",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "top_n_logo": {
      "name": "Top N logo",
      "mat": "top_n_logo",
      "color": "team red",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "lining": {
      "name": "Lining",
      "mat": "lining",
      "color": "kool grey",
      "colors": [
        "kool grey",
        "black",
        "new navy",
        "nimbus cloud"
      ]
    },
    "emb_997": {
      "name": "Emb 997",
      "mat": "emb_997",
      "color": "team red",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "emb_s": {
      "name": "Emb S",
      "mat": "emb_s",
      "color": "nimbus cloud",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "laces": {
      "name": "Laces",
      "mat": "laces",
      "color": "nimbus cloud",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "laces_holder": {
      "name": "Laces Holder",
      "mat": "laces_holder",
      "color": "black",
      "colors": [
        "kool grey",
        "black",
        "new navy",
        "nimbus cloud"
      ]
    },
    "plastic_details": {
      "name": "Plastic Details",
      "mat": "plastic_details",
      "color": "team red",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "sole": {
      "name": "Sole",
      "mat": "sole",
      "color": "black",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "tongue": {
      "name": "Tongue",
      "mat": "tongue",
      "color": "black",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "tongue_line": {
      "name": "Tongue Line",
      "mat": "tongue_line",
      "color": "black",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    }
  },
  {
    "toe": {
      "name": "Toe",
      "mat": "toe",
      "color": "nimbus cloud",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "heel": {
      "name": "Heel",
      "mat": "heel",
      "color": "nimbus cloud",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "heel_tab": {
      "name": "Heel Tab",
      "mat": "heel_tab",
      "color": "nimbus cloud",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "base_underlay": {
      "name": "Base Underlay",
      "mat": "base_underlay",
      "color": "nimbus cloud",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "base_accent": {
      "name": "Base Accent",
      "mat": "base_accent",
      "color": "nimbus cloud",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "saddle": {
      "name": "Saddle",
      "mat": "saddle",
      "color": "nimbus cloud",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "collar": {
      "name": "Collar",
      "mat": "collar",
      "color": "nimbus cloud",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "base_n_logo": {
      "name": "Base N logo",
      "mat": "base_n_logo",
      "color": "castlerock",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "top_n_logo": {
      "name": "Top N logo",
      "mat": "top_n_logo",
      "color": "nimbus cloud",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "lining": {
      "name": "Lining",
      "mat": "lining",
      "color": "nimbus cloud",
      "colors": [
        "kool grey",
        "black",
        "new navy",
        "nimbus cloud"
      ]
    },
    "emb_997": {
      "name": "Emb 997",
      "mat": "emb_997",
      "color": "black",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "emb_s": {
      "name": "Emb S",
      "mat": "emb_s",
      "color": "black",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "laces": {
      "name": "Laces",
      "mat": "laces",
      "color": "nimbus cloud",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "laces_holder": {
      "name": "Laces Holder",
      "mat": "laces_holder",
      "color": "nimbus cloud",
      "colors": [
        "kool grey",
        "black",
        "new navy",
        "nimbus cloud"
      ]
    },
    "plastic_details": {
      "name": "Plastic Details",
      "mat": "plastic_details",
      "color": "wax blue",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "sole": {
      "name": "Sole",
      "mat": "sole",
      "color": "team royal",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "tongue": {
      "name": "Tongue",
      "mat": "tongue",
      "color": "nimbus cloud",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "tongue_line": {
      "name": "Tongue Line",
      "mat": "tongue_line",
      "color": "nimbus cloud",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    }
  },
  {
    "toe": {
      "name": "Toe",
      "mat": "toe",
      "color": "team royal",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "heel": {
      "name": "Heel",
      "mat": "heel",
      "color": "nimbus cloud",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "heel_tab": {
      "name": "Heel Tab",
      "mat": "heel_tab",
      "color": "team red",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "base_underlay": {
      "name": "Base Underlay",
      "mat": "base_underlay",
      "color": "nimbus cloud",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "base_accent": {
      "name": "Base Accent",
      "mat": "base_accent",
      "color": "nimbus cloud",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "saddle": {
      "name": "Saddle",
      "mat": "saddle",
      "color": "team red",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "collar": {
      "name": "Collar",
      "mat": "collar",
      "color": "team red",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "base_n_logo": {
      "name": "Base N logo",
      "mat": "base_n_logo",
      "color": "nimbus cloud",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "top_n_logo": {
      "name": "Top N logo",
      "mat": "top_n_logo",
      "color": "team royal",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "lining": {
      "name": "Lining",
      "mat": "lining",
      "color": "nimbus cloud",
      "colors": [
        "kool grey",
        "black",
        "new navy",
        "nimbus cloud"
      ]
    },
    "emb_997": {
      "name": "Emb 997",
      "mat": "emb_997",
      "color": "team royal",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "emb_s": {
      "name": "Emb S",
      "mat": "emb_s",
      "color": "team royal",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "laces": {
      "name": "Laces",
      "mat": "laces",
      "color": "nimbus cloud",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "laces_holder": {
      "name": "Laces Holder",
      "mat": "laces_holder",
      "color": "nimbus cloud",
      "colors": [
        "kool grey",
        "black",
        "new navy",
        "nimbus cloud"
      ]
    },
    "plastic_details": {
      "name": "Plastic Details",
      "mat": "plastic_details",
      "color": "team royal",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "sole": {
      "name": "Sole",
      "mat": "sole",
      "color": "team royal",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "tongue": {
      "name": "Tongue",
      "mat": "tongue",
      "color": "nimbus cloud",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    },
    "tongue_line": {
      "name": "Tongue Line",
      "mat": "tongue_line",
      "color": "nimbus cloud",
      "colors": [
        "kool grey",
        "castlerock",
        "black",
        "new navy",
        "team royal",
        "angora cream",
        "team red",
        "NB burgundy",
        "sunlight",
        "light reef",
        "madder rose",
        "prism purple",
        "varsity green",
        "varsity orange",
        "wax blue",
        "work wear",
        "nimbus cloud"
      ]
    }
  },
];

export const modelPresetsTmb = [
  'preset1.jpg',
  'preset2.jpg',
  'preset3.jpg',
  'preset4.jpg',
];
