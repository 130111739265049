import React from 'react';
import {CartButton, Heading, HeadingWrapper} from './Header.style';

const Header = props => (
  <HeadingWrapper>
    <Heading id="header">
      <div className="wrapper">
        <div className="title">NB1 997 Sport</div>
        <div className="price">188$</div>
      </div>

      <CartButton className="add-to-cart show-review">
        <span className="header-icon icon-cart"><span>Cart</span></span>
      </CartButton>
    </Heading>
  </HeadingWrapper>
);

export default Header;
