import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectIsLoading } from 'state/selectors';
import {ApiEventsSet} from "./ModelEvents";

import { Wrapper, Loader } from './Model.style';
import {useSketchfab, useUpdateAnnotation, useUpdateLegsVisible} from './Model.hooks';
import { modelUid, modelOptions } from 'consts/sketchfab';
import {annotationLimits} from "../../consts/model-conf";

const Model = (props) => {
  const iframeId = 'sweater';
  const isLoading = useSelector(selectIsLoading);
  const legsVisible = props.legsState;
  const annotationState = props.annotation;

  // Dirty solution for autospin: just switch annotation on interval
  const activateAnnotSpin = function(api) {
    const limits = annotationLimits.default;
    let current = limits.min;

    window.spinInterval = setInterval(() => {
      current = current >= limits.max ? limits.min : current + 1;
      api.gotoAnnotation(current);
      console.warn('AUTOSPIN', current);
    }, 1400);

    window.sf_stop_spinner = function() {
      if (window.spinInterval) {
        clearInterval(window.spinInterval);
        window.spinInterval = false;
      }
    };
    // Any click on body will delete timer. Also see ModelEvents.js
    document.body.addEventListener('click', (e) => window.sf_stop_spinner(), true);
  };

  const onModelReadyHandler = function(api) {
    api.hideNode('hide');
    api.gotoAnnotation(annotationLimits.default.min);
    props.setCameraLimit('default');
    // activateAnnotSpin(api);
  };

  const [materials, sketchfabApi] = useSketchfab({
    uid: modelUid,
    iframeId,
    options: modelOptions,
    onModelReady: onModelReadyHandler
  });

  if (sketchfabApi) {
    if (!window.sf_events_set) {

      ApiEventsSet(sketchfabApi, props.parts, {
        changeMat: props.changeCurrentMat,
        setColorPanelVis: props.setColorPanelVis,
        setHintState: props.setHintState
      });

      window.sf_events_set = true;
    }
  }

  // Watching annotationState for change annotation on model
  // const [ annotationCurrent, setCurrentAnnotation ] = useState(annotationLimits.default.min + 1);
  // useUpdateLegsVisible(sketchfabApi, legsVisible, setCurrentAnnotation);
  useUpdateAnnotation(sketchfabApi, annotationState, props.annotationCurrent, props.setCurrentAnnotation, legsVisible);

  if (sketchfabApi && false) {
    sketchfabApi.addEventListener(
      'click',
      function(event) {
        // eslint-disable-next-line no-console
        console.log(event.material);
      },
      { pick: 'slow' }
    );
  }

    if (materials) {
      props.setMaterials(materials);
    }

    const loaderClass = isLoading ? 'vis' : 'hid';

  return (
    <Wrapper isLoading={isLoading}>
        <Loader className={"loader " + loaderClass}>
          <img src="/assets/loading.gif?x2" alt="" />
        </Loader>
      <iframe title="Sketchfab Model" id={iframeId} />

      {/*{materials && (
        <div className="materialsWrapper">
          Available Materials:
          <ul className="materialsList">
            {materials.map(m => (
              <li key={m.name}>{m.name}</li>
            ))}
          </ul>
        </div>
      )}*/}
    </Wrapper>
  );
};

export default Model;
