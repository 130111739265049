export const initialState = {
  isLoading: true,

};

export const SET_START_LOADING = 'SET_START_LOADING';
export const SET_STOP_LOADING = 'SET_STOP_LOADING';

export default function reducer(state = initialState, action = { type: '', payload: undefined }) {
  switch (action.type) {
    case SET_START_LOADING: {
      const newState = {
        ...state,
        ...initialState,
        isLoading: true // action.payload
      };

      return newState;
    }

    case SET_STOP_LOADING: {
      const newState = {
        ...state,
        ...initialState,
        isLoading: false
      };

      return newState;
    }

    default:
      return state;
  }
}

export const setStartLoading = newValue => ({
  type: SET_START_LOADING,
  payload: newValue
});

export const setStopLoading = newValue => ({
  type: SET_STOP_LOADING,
  payload: newValue
});
